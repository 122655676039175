import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { setProfileAvatar } from '../state/profile';
import { fileService } from '../services/file';
import { profileService } from '../services/profile';
import { useCetEvents } from 'cet-components-lib/dist/hooks';

export const useProfile = () => {
  const intl = useIntl();
  const { sendUiUsageEvent } = useCetEvents();
  const dispatch = useDispatch();
  const profile = useSelector(state => state.profile ?? {});

  const updateProfileAvatar = useCallback(
    async blob => {
      const url = await fileService.uploadBlob(blob, 'usersuploads', 'avatrs');
      const thumbnailId = await profileService.updateThumbnail(profile.studentId, url);

      dispatch(setProfileAvatar(thumbnailId));
    },
    [dispatch, profile.studentId]
  );

  const updateUiLanguage = useCallback(
    async uiLanguage => {
      await profileService.updateUiLanguage(profile.studentId, profile.schoolId, uiLanguage);

      window.location.replace(window.location.href.replace(intl.locale, uiLanguage));
    },
    [intl.locale, profile.schoolId, profile.studentId]
  );

  const logout = useCallback(() => profileService.logout(intl.locale, sendUiUsageEvent), [intl.locale, sendUiUsageEvent]);

  const changeSchool = useCallback(() => profileService.changeSchool(intl.locale), [intl.locale]);

  return {
    updateProfileAvatar,
    updateUiLanguage,
    logout,
    changeSchool
  };
};
