import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CacheProvider } from '../utils/cacheProvider';
import { isSearchTermNothingButNumber } from '../utils/search';

export const parameterFormation = {
  ageGrades: 'dimensionParams',
  authors: 'dimensionParams',
  disciplines: 'dimensionParams',
  itemTypes: 'dimensionParams',
  pedagogicValues: 'dimensionParams',
  publishers: 'dimensionParams',
  sectors: 'dimensionParams',
  generalSkills: 'dimensionParams',
  generalSubskills: 'dimensionParams',
  skills: 'dimensionParams',
  subskills: 'dimensionParams',
  syllabusDetails: 'dimensionParams',
  syllabusSubjects: 'dimensionParams',
  syllabusSubsubjects: 'dimensionParams',
  yearCycles: 'dimensionParams',
  activityTypes: 'contentParams',
  isMobileSupported: 'contentParams',
  subActivityTypes: 'contentParams',
  createdDate: 'contentParams',
  publishedDate: 'contentParams',
  updatedDate: 'contentParams',
  itemSource: 'contentParams',
  itemContext: 'contentParams',
  contextLanguage: 'contentParams',
  duration: 'contentParams',
  doNotSearch: 'contentParams',
  doNotImport: 'contentParams',
  id: 'contentParams',
  isPublished: 'contentParams',
  externalContextId: 'contentParams',
  title: 'textParams',
  summary: 'textParams',
  discipline: 'textParams',
  syllabusSubject: 'textParams',
  skill: 'textParams',
  itemType: 'textParams',
  activityType: 'textParams',
  ageGrade: 'textParams',
  allFields: 'textParams'
};

const allSectors = ['state', 'mmad', 'arab'];

const search =
  (intl, allDimensions, sectors) =>
  /**
   *
   * @param {Array.<Object>} searchByParameters Array of search parameters. ex: [{p2: [1,2,3]}, {p2: [9,8,7]}]
   * @param {string} groupBy Any dimension name to group by.
   * @param {string} orderBy
   * @param {string} searchFunk the search function to use. 'search', 'parallelSearchFast' or 'filter'
   * @param {boolean} withSectors
   * @param {boolean} skipCache
   * @param {number} maxResults Max number of item per group
   * @param {number|null} statsType NUll - no statistics, 1 - statistics on all item Types, 2 - statistics on all item Types from the filter field(itemTypes)
   */
  async (searchByParameters, groupBy, maxResults, orderBy = 'lastCatalogPublishedDate', skipCache = true, searchFunk = 'search', withSectors = true, statsType = 1) => {
    if (!allDimensions || (withSectors && !sectors)) return null;
    if(isSearchTermNothingButNumber(searchByParameters)) return [];

    if (searchByParameters) {
      const dimensionKey = groupBy || 'itemTypes';

      const createApiParameters = searchByParameters => {
        const apiParameters = {
          lang: intl.locale,
          skipCache,
          orderBy,
          statsType,
          dimensionParams: {
            sectors: withSectors ? (Array.isArray(sectors) ? sectors : [sectors]) : allSectors,
            audiences: ['students']
          },
          contentParams: {},
          textParams: {},
          retrieveParams: {}
        };

        if (groupBy && searchFunk !== 'filter') {
          apiParameters.retrieveParams.dimensionName = groupBy;
        }

        if (orderBy) {
          apiParameters.retrieveParams.orderBy = orderBy;
        }

        if (maxResults) {
          apiParameters.retrieveParams.maxResults = maxResults;
        }

        searchByParameters.forEach(parameter => {
          const parameterName = Object.keys(parameter)[0];

          if (parameterFormation[parameterName]) {
            apiParameters[parameterFormation[parameterName]][parameterName] = parameter[parameterName];
          } else {
            apiParameters[parameterName] = parameter[parameterName];
          }
        });

        apiParameters.contentParams.isPublished = [true];

        return apiParameters;
      };

      let apiParameters;
      if (searchFunk === 'parallelSearchFast') {
        apiParameters = [];
        searchByParameters.forEach(params => {
          apiParameters.push(createApiParameters(params));
        });
      } else {
        apiParameters = createApiParameters(searchByParameters);
      }

      let localCacheKey = CacheProvider.generateKey('search', apiParameters);
      let items;
      if (CacheProvider.get(localCacheKey)) {
        items = CacheProvider.get(localCacheKey);
      } else {
        let response = {};

        try {
          response =
            searchFunk in window.cet.microservices.contentapi.search
              ? await window.cet.microservices.contentapi.search[searchFunk](apiParameters)
              : await window.cet.microservices.contentapi.search.search(apiParameters);
        } catch (error) {
          console.error(error);
        }

        const sortByReference = allDimensions[dimensionKey].map(item => item.id);

        const getResults = response => {
          const items = [];

          Object.keys(response?.records).forEach(key => {
            let title = null;
            if (allDimensions && groupBy && allDimensions[groupBy]) {
              const filterDimensions = allDimensions[groupBy].filter(d => d.id === key);
              if (filterDimensions.length > 0) {
                title = filterDimensions[0].name;
              }
            }
            if (!title && response?.records[key].length > 0 && response?.records[key][0][dimensionKey][key]) {
              title = response.records[key][0][dimensionKey][key];
            }
            if (!title) {
              title = intl.formatMessage({ id: key, defaultMessage: '' });
            }

            if (response?.records[key].length > 0) {
              response.records[key].forEach(item => {
                if (item[dimensionKey] && Object.keys(item[dimensionKey]).length > 1) {
                  item[dimensionKey] = Object.keys(item[dimensionKey])
                    .sort((a, b) => (sortByReference.indexOf(a) < sortByReference.indexOf(b) ? -1 : sortByReference.indexOf(a) > sortByReference.indexOf(b) ? 1 : 0))
                    .reduce((acc, key) => ({ ...acc, [key]: item[dimensionKey][key] }), {});
                }
              });
            }

            items.push({
              [dimensionKey]: key,
              title: title,
              stats: false,
              data: response?.records[key],
              totalCount: response?.stats?.dimensionFound[key]
            });
          });

          items.sort((a, b) =>
            sortByReference.indexOf(a[dimensionKey]) < sortByReference.indexOf(b[dimensionKey])
              ? -1
              : sortByReference.indexOf(a[dimensionKey]) > sortByReference.indexOf(b[dimensionKey])
              ? 1
              : 0
          );

          const dimensions = Object.keys(response?.stats?.dimensionFound) || [];

          dimensions.forEach(dimension => {
            let title = allDimensions.itemTypes.find(item => item.id === dimension)?.name;
            title = title || intl.formatMessage({ id: dimension, defaultMessage: '' });
            items.push({
              itemTypes : dimension,
              title,
              stats: true,
              totalCount: response.stats.dimensionFound[dimension]
            });
          });

          return items;
        };

        if (searchFunk === 'parallelSearchFast') {
          items = [];
          response?.forEach(responseData => {
            items.push(getResults(responseData));
          });
        } else {
          items = getResults(response);
        }

        CacheProvider.set(localCacheKey, JSON.stringify(items));
      }

      return items;
    }
  };

export const useSearch = () => {
  const intl = useIntl();

  const { allDimensions, schoolSectorId } = useSelector(({ profile, dimensions }) => {
    return {
      allDimensions: dimensions.dimensions,
      schoolSectorId: profile?.schoolSectorId
    };
  });

  return useMemo(() => search(intl, allDimensions, schoolSectorId), [allDimensions, intl, schoolSectorId]);
};
